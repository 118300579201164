import React from 'react';
import { graphql } from 'gatsby';

import { PageManager } from '+/components/PageManager';
import cleanupData from '+/utils/cleanupData';

/*
 * For netlify-built pages. Uses the gatsby system for fetching graphql.
 */
const ServerDirectusPage = ({ data, pageContext }: ServerDirectusPageComponent) => {
  const { domainName } = pageContext;

  const pageData = cleanupData(
    data.directus.pages.filter(
      ({ domain = {} }) => !domainName || (domain && domain.domain_name) === domainName,
    )[0],
  ) as PageComponent;
  if (!pageData) return null;
  return <PageManager pageData={pageData} />;
};

export default ServerDirectusPage;

export const query = graphql`
  query AllPagesDataBySlug($slug: String!) {
    directus {
      pages(filter: { status: { _eq: "published", }, slug: { _eq: $slug } }) {
        ...pageData
      }
    }
  }
`;
